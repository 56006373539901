<script setup lang="ts">
import Button from "@frontend/ui-kit/src/components/Base/Button.vue";
import PhoneNumberInput from "@frontend/ui-kit/src/components/Base/PhoneNumberInput.vue";
import { IdentifyType } from "~/types/user/enum";
import { useAuthorization } from "~/composables/auth/useAuthorization";
import { useI18nValidators } from "~/plugins/i18n/i18n-validators";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import Countdown from '@frontend/ui-kit/src/components/Base/Countdown.vue';
import customValidateHelper from '@frontend/ui-kit/src/utils/customValidateHelper';
import { jwtDecode } from "jwt-decode";
import {VueRecaptcha} from "vue-recaptcha";
import { useNotificationStore } from '~/stores/alert/useNotificationStore';
import {ExtendedHttpStatus} from "~/constants/extended-http-status";

const { t } = useI18n();
const gtm = useGtm()
const { required, minLength, maxLength } = useI18nValidators();

const smsCodeLength = 4;
const phoneLength = 15;
const sendingCodeTimeout = 179;
const phone = ref<string>('');
const isShowCode = ref<boolean>(false);
const isTimerShow = ref<boolean>(false);
const captchaToken = ref<string | undefined>(undefined);
const isShowCaptcha = ref<boolean>(false)

const codeInput = ref<HTMLElement | null>(null);

const code = ref<string>('');
const isErrorCode = ref<boolean>(false);

const { sendSmsCode } = useAuthorization();
const { authorize } = useAuthorization();
const notificationStore = useNotificationStore()
const config = useRuntimeConfig();

const emit = defineEmits(['logged']);

const rulePhone = computed(() => ({
    phoneNumber: {
        required,
        minLength: minLength(phoneLength),
        maxLength: maxLength(phoneLength),
        validate: helpers.withMessage(t('forms.validations.invalidFormat'),
            (value: string) => {
                return customValidateHelper.phoneNumber(value.replace(/\s+/g, ''));
            })
    }
}));
const ruleCode = computed(() => ({
    code: { required, minLength: minLength(smsCodeLength), maxLength: maxLength(smsCodeLength) }
}));
const $vPhone = useVuelidate(rulePhone, { phoneNumber: phone });
const $vCode = useVuelidate(ruleCode, { code: code });

onMounted(() => {
    focusField('phone');
    window.addEventListener('keydown', handleKeyDown);
})
onUnmounted(() => {
    // Удаляем обработчик события при уничтожении компонента
    window.removeEventListener('keydown', handleKeyDown);
})

const handleKeyDown = async (event: KeyboardEvent) : Promise<void> => {
    if (event.key === 'Enter') {
        await onSendCode();
    }
}

const onSendCode = async () : Promise<void> => {
    trackEvent("form_login_submit");
    const isFormCorrect = await $vPhone.value.$validate();
    if(!isFormCorrect) {
        $vPhone.value.$touch();
        return;
    }

    const phoneNumber = getPhoneNumber();

    if(isShowCaptcha.value && (captchaToken.value === undefined || captchaToken.value.length === 0)) {
        notificationStore.showErrorText(t, t('login.validations.requiredCaptcha'));
        return;
    }

    const result = await sendSmsCode(phoneNumber, captchaToken.value);

    if(!result.isError) {
        code.value = '';
        isShowCode.value = true;
        activateTimerSendCode();

        await nextTick(() => {
            codeInput.value?.focus();
        });
    } else {
        focusField('phone');

        if (result.statusCode === ExtendedHttpStatus.RetryWith) {
            isShowCaptcha.value = true
        } else {
            notificationStore.showErrorText(t, result.error);
        }
    }
};

const onAuthorize = async (code: string) : Promise<void> => {
    isErrorCode.value = false;

    const isFormCorrect = await $vCode.value.$validate();
    if (!isFormCorrect) {
        $vCode.value.$touch();
        return;
    }

    const data = {
        userName: (phone.value ?? '').replace(/\D/g, ""),
        password: code,
        identifyType: IdentifyType.Sms
    } as ILoginData;
    const result = await authorize(data);
    if(!result.isError) {
        const tokenCookie = useCookie("token");
        tokenCookie.value = result.data;
        const json = jwtDecode(tokenCookie.value!);

        trackEvent("login_submit",  "login_success", json.sub);

        emit('logged');
    } else {
        trackEvent("login_submit", "login_failed");
        isErrorCode.value = true;
    }
};

const onCodeFinished = async () : Promise<void> => {
    if(code.value.length == smsCodeLength) {
        await onAuthorize(code.value);
    }
}

const onReturnToPhone = () : void => {
    isShowCode.value = false;
    isErrorCode.value = false;
    $vCode.value.$reset();
    isTimerShow.value = false;
    nextTick(() => {
        focusField('phone');
    });
}

const focusField = (id: string ) : void => {
    const element = document.getElementById(id);
    if(element) {
        element.focus();
    }
}

const getPhoneNumber = () : string => {
    return (phone?.value ?? '').replace(/\D/g, "");
}

const activateTimerSendCode = () : void => {
    isTimerShow.value = true;
    setTimeout(() => isTimerShow.value = false, 1000 * sendingCodeTimeout);
}

const trackEvent = (eventName: string, loginStatus?: string, userId?: string): void => {
    const eventData: Record<string, string> = {
        event: eventName,
        ...(userId && { user_id: userId }),
        ...(loginStatus && { login_status: loginStatus }),
    };

    gtm?.trackEvent(eventData);
}

const onCaptchaSuccess = (response: string) => {
    captchaToken.value = response;
};
const onCaptchaExpired = () => {
    captchaToken.value = undefined;
}
</script>

<template>
    <div v-show="!isShowCode">
        <span class="title">{{ $t("login.title") }}</span>
        <br/>
        <span class="subtitle">{{ $t("login.subtitle") }}</span>
    </div>
    <div v-show="!isShowCode" class="login-field">
        <PhoneNumberInput
            id="phone"
            v-model="phone"
            :isAlwaysShowNumber="true"
            :debounceTime="0"
            placeholder="+7 XXX XXX XX XX"
            :errors="$vPhone.phoneNumber.$errors"
        />
        <div class="login-btn">
            <VueRecaptcha
                v-if="isShowCaptcha"
                class="captcha"
                :sitekey="config.public.captchaSiteKey"
                :loadRecaptchaScript="true"
                @verify="onCaptchaSuccess"
                @expired="onCaptchaExpired"
            />

            <Button
                id="continue"
                ref="continueButton"
                class="button"
                @click="onSendCode()"
            >
                {{ $t("forms.shared.phrases.continue") }}
            </Button>
        </div>
    </div>

    <div v-if="isShowCode">
        <span class="title">{{ $t("login.titleSendCode") }}</span>
        <br/>
        <span class="subtitle">{{ $t("login.subtitleSendCode") }} {{ phone }}</span>
    </div>
    <div v-if="isShowCode" class="login-field">
        <v-otp-input
            ref="codeInput"
            v-model="code"
            :length="4"
            autofocus
            variant="outlined"
            class="code"
            width="230px"
            height="65px"
            :class="{ error: $vCode.code.$errors.length > 0 || isErrorCode}"
            @finish="onCodeFinished">
        </v-otp-input>
        <div
            v-if="isErrorCode"
            class="error-message">
            {{ $t('login.validations.invalidCode') }}
        </div>
        <div
            v-for="error of $vCode.code.$errors"
            :key="error.$uid"
            class="error-message">
            {{ error.$message }}
        </div>
        <div class="description">
            <slot name="description"/>
        </div>
    </div>
    <div v-show="isShowCode" class="login-field">
        <div class="links">
            <div v-if="isTimerShow" class="timer">
                {{ $t('login.repeatSending') }}
                <Countdown :time="sendingCodeTimeout" />
            </div>
            <div v-else class="link-icon">
                <a class="link" @click.prevent="onSendCode">
                    {{ $t('login.repeatSending') }}
                </a>
                <i class="ri-check-line link"/>
            </div>
            <a class="link" @click.prevent="onReturnToPhone">
                {{ $t('login.otherNumber') }}
            </a>
        </div>
    </div>
</template>

<style scoped lang="scss">
.title {
    color: $main-active-color;

    font-size: 20px;
    font-family: $ff-bold;
}

.subtitle {
    color: $color-neutral;

    font-size: 20px;
    line-height: 125%;
    letter-spacing: -0.2px;
    font-family: $ff-bold;
}

.login-field {
    width: 100%;
}

.login-btn {
    padding-top: 10px;

    .captcha {
        margin-bottom: 10px;
    }
}

.button {
    width: 100%;
}

.links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .timer {
        color: $color-neutral;

        font-size: 13px;
        font-weight: 400;
    }

    .link-icon {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .link {
        cursor: pointer;

        font-size: 13px;
        font-weight: 400;
    }
}

.code {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
/** Ошибка в данных */
.error {
    .icon {
        padding-left: 15px;
        color: $error-color;
    }

    :deep(.v-field) {
        border-color: $error-color;

        .v-field__outline__start,
        .v-field__outline__end {
            border-color: $error-color;
        }
    }
}

.error-message {
    color: $error-color;

    font-size: 12px;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: -0.12px;
}
</style>
